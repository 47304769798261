import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import IconClose from '../../images/icon-close.png';

export const ModalBlocker = styled(Modal)`
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  .modal-content {
    max-width: 500px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ModalChoosePropane = styled.div`
  max-width: 500px;
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  text-align: center;
  border-radius: 6px;
  padding: 40px !important;
  opacity: 1;
  display: inline-block;
`;

export const ModalChoosePropaneLink = styled.a`
  display: block;
  float: left;
  width: 50%;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  :hover {
    color: #f7991c;
  }
  @media (max-width: 767px) {
    :first-of-type {
      margin-bottom: 20px;
    }

    float: none;
    width: 100%;
    font-size: 30px;
    line-height: 30px;
  }
`;
export const ModalChoosePropaneClose = styled.a`
  position: absolute;
  z-index: 9999999999;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${IconClose});
  background-size: 30px auto;
`;

export const ModalChoosePropaneSpan = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 25px;
  color: #ffc905;
  @media (max-width: 767px) {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 20px;
  }
`;
