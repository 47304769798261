import * as React from 'react';
import SVGWithLink from '../../common/svgWithLink.js/svgWithLink';
import { DELAY, SECTIONS } from '../../constants';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import * as Styled from './deliveringMoreStyles';

const DeliveringMore = ({ deliveringMoreData }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const handleModalShow = (isShow) => {
    isShow ? setModalShow(isShow) : setModalShow(!modalShow);
  };
  const svgIconDataList = deliveringMoreData?.filter((section) => {
    return (
      section?.type === SECTIONS.HOMEPAGE.DELIVERING_MORE_LIST.PRODUCT_LIST
    );
  })[0]?.listOfImagesWithPath;
  const headerContent = deliveringMoreData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.DELIVERING_MORE_LIST.HEADER;
  })[0];
  const eagleContent = deliveringMoreData?.filter((section) => {
    return (
      section?.type === SECTIONS.HOMEPAGE.DELIVERING_MORE_LIST.EAGLE_CONTENT
    );
  })[0];
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.DeliveringMoreProductsHeading>
          {children}
        </Styled.DeliveringMoreProductsHeading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <></>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <img src={asset?.node?.file?.url} alt={asset?.node?.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      <Styled.DeliveringMoreClearFix>
        <Styled.DeliveringMoreEagle>
          {eagleContent?.contentDetails &&
            documentToReactComponents(
              JSON.parse(eagleContent?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.DeliveringMoreEagle>
        <Styled.DeliveringMoreProducts>
          <div>
            {headerContent?.contentDetails &&
              documentToReactComponents(
                JSON.parse(headerContent?.contentDetails?.raw),
                optionsMainStyle
              )}
          </div>
          <Styled.DeliveringMoreProductList>
            {svgIconDataList?.map((svgIconData, idx) => {
              return (
                <SVGWithLink
                  key={idx}
                  svgIconData={svgIconData}
                  delay={DELAY.homePromo * (idx + 1)}
                />
              );
            })}
          </Styled.DeliveringMoreProductList>
        </Styled.DeliveringMoreProducts>
      </Styled.DeliveringMoreClearFix>
    </>
  );
};

export default DeliveringMore;
