import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Cookies from 'universal-cookie';
import * as Styled from './propanePopupStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const PropanePopup = ({ showModal }) => {
  const data = useStaticQuery(graphql`
    query PropanePopupQuery {
      allContentfulUltramarCommercial(
        filter: { identifier: { type: { eq: "propanePopUp" } } }
      ) {
        edges {
          node {
            __typename
            node_locale
            title
            slug
            identifier {
              type
            }
            sections {
              ... on ContentfulContentList {
                id
                list {
                  type
                  title
                  subText
                  contentDetails {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const cookies = new Cookies();
  const propanePopupData = data?.allContentfulUltramarCommercial?.edges.find(
    (edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
  );
  const propanePopupSection = propanePopupData?.node?.sections[0];
  const subText = propanePopupData?.node?.sections[0]?.list[0]?.subText;

  const optionsMainStyle = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ModalChoosePropaneLink
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {subText && (
            <Styled.ModalChoosePropaneSpan>
              {subText}
            </Styled.ModalChoosePropaneSpan>
          )}
          {children}
        </Styled.ModalChoosePropaneLink>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.ModalBlocker show={showModal} centered backdrop="static">
      <Styled.ModalChoosePropane>
        {propanePopupSection?.list?.map((item, index) => (
          <div key={index}>
            {item?.contentDetails &&
              documentToReactComponents(
                JSON.parse(item?.contentDetails?.raw),
                optionsMainStyle
              )}
          </div>
        ))}
        <Styled.ModalChoosePropaneClose
          onClick={() => showModal(false)}
        ></Styled.ModalChoosePropaneClose>
      </Styled.ModalChoosePropane>
    </Styled.ModalBlocker>
  );
};

export default PropanePopup;
