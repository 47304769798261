import styled from 'styled-components';

export const DeliveringMoreProductListItem = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: #f7991c;
  }
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 25px;
  line-height: 25px;
  color: #ffc905;
  cursor: pointer;
  margin: 0 30px;
  margin-bottom: 0;
  display: inline-block;
  @media (max-width: 1439px) {
    padding: 0 15px;
    font-size: 20px;
    line-height: 20px;
    margin: 0;
    :nth-child(1) {
      width: 21%;
    }
    :nth-child(2) {
      width: 29%;
    }
    :nth-child(3) {
      width: 26%;
    }
    :nth-child(4) {
      width: 24%;
    }
  }
  @media (max-width: 1023) {
    font-size: 18px;
    line-height: 18px;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    padding: 0;
    :nth-child(1) {
      width: 60px;
    }
    :nth-child(2) {
      width: 70px;
    }
    :nth-child(3) {
      width: 75px;
    }
    :nth-child(4) {
      width: 80px;
    }
  }
`;

export const DeliveringMoreProductListImage = styled.img`
  display: block;
  width: auto;
  height: 98px;
  margin: 0 auto 35px auto;
  transition: all 0.25s ease-out;
  :hover {
    transform: scale(1.15);
  }
  @media (max-width: 1439px) {
    height: 70px;
    margin: 0 auto 35px auto;
  }
  @media (max-width: 1023px) {
    height: 80px;
  }
  @media (max-width: 767px) {
    height: 45px;
    margin-bottom: 20px;
  }
`;
